















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { BButton, BSpinner, BFormInput } from 'bootstrap-vue'
import { cart } from '@/store/cart/cartModule'
import { customer } from '@/store/customer'

@Component({
  components: { BButton, BSpinner, BFormInput }
})
export default class LoyaltyProgram extends Vue {
  rangeValue: number | string = 0
  maxPercentPoint = 0.99
  private showScanMessage = false

  @Watch('totalPrice')
  @Watch('isAuthenticated')
  setRangeValue(): void {
    this.rangeValue = this.maxRangeScore
  }

  get isAuthenticated(): boolean {
    return !!customer.customerId
  }

  get isLoading(): boolean {
    return this.scoresCount === null
  }

  get scoresCount(): number | null {
    return customer.scores
  }

  get spend() {
    return customer.spendScores
  }

  get error() {
    return customer.error
  }

  get commonPrice(): number {
    return cart.commonPrice
  }

  get totalPrice(): number {
    return cart.totalPrice
  }

  get maxRangeScore(): number {
    // максимальное списание бонусов может быть 99%
    return Math.floor(Math.min(this.scoresCount ?? 0, cart.totalPrice) * this.maxPercentPoint)
  }

  spendScores(): void {
    const usedPoints = typeof this.rangeValue === 'string' ? parseInt(this.rangeValue, 10) : this.rangeValue
    cart.payWithScores(usedPoints)
    this.$emit('spend')
  }

  showMessage(): void {
    this.showScanMessage = true
  }

  mounted():void {
    this.setRangeValue()
  }
}
